import { render, staticRenderFns } from "./addCollections.vue?vue&type=template&id=79e63bff"
import script from "./addCollections.vue?vue&type=script&lang=js"
export * from "./addCollections.vue?vue&type=script&lang=js"
import style0 from "./addCollections.vue?vue&type=style&index=0&id=79e63bff&prod&lang=stylus&rel=stylesheet%2Fstylus"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports